<template>
  <nav class="navbar navbar-expand-xl border-0 bg-primary">
    <div class="container py-1">
      <button
        class="ml-auto p-2 d-block d-lg-none"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <i class="fa-solid fa-bars fs-20 text-white"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav pl-0">
          <li
            v-for="(nav, i) in navItems"
            :key="i"
            :class="`nav-item mr-sm-7 ${nav.children ? 'dropdown' : ''}`"
          >
            <router-link
              :class="`${aClass} ${setActiveClass(nav.path)}`"
              :to="nav.path"
              v-if="!nav.children"
            >
              <div class="icon">
                <i :class="nav.icon"></i>
              </div>
              <span class="ms-2">{{ nav.title }}</span>
            </router-link>

            <a
              :class="`dropdown-toggle ${aClass} ${setActiveClass(nav.path)}`"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :id="nav.title"
              v-if="nav.children"
            >
              <div class="icon">
                <i :class="nav.icon"></i>
              </div>
              <span class="ms-2">{{ nav.title }}</span>
            </a>
            <ul
              class="dropdown-menu pl-2 pr-2 shadow bg-primary"
              :aria-labelledby="nav.title"
            >
              <li v-for="(nav2, j) in nav.children" :key="j">
                <router-link
                  :to="nav2.path"
                  class="dropdown-item my-2 py-2 fs-14 fw-light text-white rounded"
                >
                  <div class="icon">
                    <i :class="nav2.icon"></i>
                  </div>
                  <span class="ms-2">{{ nav2.title }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div
        class="offcanvas offcanvas-end d-block d-md-none bg-primary"
        tabindex="-1"
        id="offcanvasRight"
        style="width: 300px"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">
            <!-- <div class="d-flex align-items-center text-black">
              <img src="@/assets/logo.svg" width="60" alt="logo" />
            </div> -->
          </h5>
          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close">
            <i class="fa-solid fa-xmark fs-24 text-white"></i>
          </button>
        </div>
        <div class="offcanvas-body">
          <ul class="list-unstyled pl-0">
            <li v-for="(nav, i) in navItems" :key="i">
              <router-link
                :class="`${aClass} py-2 mb-1`"
                :to="nav.path"
                v-if="!nav.children"
              >
                <div class="icon">
                  <i :class="nav.icon"></i>
                </div>
                <span class="ms-2">{{ nav.title }}</span>
              </router-link>

              <ul class="list-unstyled pl-0">
                <li v-for="(nav2, j) in nav.children" :key="j">
                  <router-link :to="nav2.path" :class="`${aClass} py-2 mb-1`">
                    <div class="icon">
                      <i :class="nav2.icon"></i>
                    </div>
                    <span class="ms-2"> {{ nav2.title }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppTopbarNavigation",
  data() {
    return {
      aClass: "nav-link px-3 text-white fs-14 fw-light text-white-50",
      navItems: [
        {
          title: "Home",
          path: "/home",
          icon: "fa-solid fa-house",
          role: ["BC", "USER", "ADMIN"],
          children: false,
        },
        {
          title: "Realisasi",
          path: "/realisasi",
          icon: "fa-solid fa-clipboard-check",
          role: ["USER", "ADMIN"],
          children: false,
        },
        {
          title: "Barang",
          path: "/barang",
          icon: "fa-solid fa-boxes-stacked",
          role: ["BC", "USER", "ADMIN"],
          children: false,
        },
        {
          title: "User",
          path: "/user",
          icon: "fa-solid fa-users",
          role: ["ADMIN"],
          children: false,
        },
        {
          title: "Riwayat",
          path: "/history",
          icon: "fa-solid fa-clock-rotate-left",
          role: ["BC", "USER", "ADMIN"],
          children: false,
        },
        {
          title: "Referensi",
          path: "/referensi",
          icon: "fa-solid fa-book",
          role: ["BC", "USER", "ADMIN"],
          children: [
            {
              title: "Kurs Mata Uang",
              path: "/referensi/kurs-mata-uang",
              icon: "fa-solid fa-dollar",
              children: false,
            },
            {
              title: "Pelabuhan",
              path: "/referensi/pelabuhan",
              icon: "fa-solid fa-ship",
              children: false,
            },
            {
              title: "Negara",
              path: "/referensi/negara",
              icon: "fa-solid fa-flag",
              children: false,
            },
            {
              title: "Satuan",
              path: "/referensi/satuan",
              icon: "fa-solid fa-balance-scale",
              children: false,
            },
            {
              title: "Satuan Terkecil",
              path: "/referensi/satuan-terkecil",
              icon: "fa-solid fa-balance-scale-right",
              children: false,
            },
          ],
        },
        {
          title: "Dokumen",
          path: "/dokumen",
          icon: "fa-solid fa-folder",
          role: ["USER", "ADMIN"],
          children: false,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.app.user;
    },
  },
  methods: {
    setActiveClass(path) {
      return this.$route.path.includes(path) ? "router-link-exact-active" : "";
    },
  },
  created() {
    this.navItems = this.navItems.filter((item) =>
      item.role.includes(this.user.role)
    );
  },
};
</script>

<style lang="scss">
.router-link-exact-active {
  font-weight: 500 !important;
  border-radius: 5px;
  color: white !important;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
}

.dropdown-item {
  &:active,
  &:hover,
  &:focus,
  &:after {
    background: none;
  }
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}
</style>
